.App {
  text-align: center;
}

.App-logo {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 5px;
  width: 100px;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.list-container {
  height: 80vh;
  overflow-y: auto;
  padding: 0px 10px;
}

.list-item {
  text-align: left;
  padding: 5px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0px 0px 5px #e4e4e4;
  margin: 10px 0px;
  background-color: white;
}

.list-item:hover {
  box-shadow: 0px 0px 5px #545454;
  background-color: #e0e0e0;
}

.map-info-window {
  position: absolute;
  transform: translate(15px, -55%);
  width: 200px;
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 10px;
  z-index: 1;
  box-shadow: 0px 0px 5px #dedede;
}

.map-info-close {
  position: fixed;
  border: none;
  background-color: transparent;
  right: -15px;
  top: -10px;
  z-index: 100;
}

.map-container {
  height: 100%;
}

.at-product__container {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.at-product__card {
  padding: 3rem !important;
}

@media screen and ( max-width: 570px ){
  .map-container {
    height: 100vh;
  }

  .App-logo {
    width: 70px;
  }

  .at-product__container {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .at-product__card {
    padding: 1rem !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.introduction_section {
  margin-top: 50px;
}

.at-company-background p {
  font-size: 20px;
  font-weight: 400;
  font-family: Comic Sans MS, Comic Sans, cursive;
  border-radius: 10px;
  padding: 30px;
}

hr {
  width: 200px;
  margin: 15px auto;
  color: #a48a74;
  height: 5px !important;
  opacity: 1;
  border-radius: 20px;
}

.introduction_section .introduction_bg {
  background-image: url("./introduction_bg.jpeg");
  height: 600px;
  width: 100%;
  opacity: 0.7;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(2px);
}

.introduction_section .intro1{
  padding: 5px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.introduction_section .intro2{
  width: 100%;
  height: 50%;
  padding: 5px;
  object-fit: cover;
}

.introduction_section .content {
  position: absolute;
  transform: translateY(-550px);
  transition: all 2s ease;
  width: 100%;
}

.introduction_section .content .enter {
  transform: translateY( 50vh );
}

.concept_section {
  margin-top: 50px;
  background-color: #e6e2d6;
  padding-top: 50px;
}

.concept_section .concept1_shadow {
  width: 250px;
  height: 333.33px;
  border-radius: 10px;
  position: absolute;
  background-color: #a48a74;
  transform: translate(123px, 3px);
}

.concept_section .concept1 {
  width: 250px;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  transform: translate(-127px, 10px);
}

.concept_section .concept2 {
  width: 350px;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  transform: translate(-186px, 10px);
}

.concept_section .concept2_shadow {
  width: 350px;
  height: 230px;
  border-radius: 10px;
  position: absolute;
  background-color: #a48a74;
  transform: translate(65px, 3px);
}

.aboutUs_section {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-top: 50px;
  background-color: #6c606a;
}

.aboutUs_section p {
  color: white;
  display: flex;
}

.aboutUs_section span {
  padding: 0px 100px;
  font-size: 26px;
}

.aboutUs_section span hr {
  width: 100px;
  margin: 5px;
}

@media screen and ( max-width: 1020px  ) {
  .concept_section .concept1_shadow {
    transform: translate(40px, 3px);
  }

  .concept_section .concept2_shadow {
    transform: translate(5px, 3px);
  }
}

@media screen and ( max-width: 767px ) {
  .introduction_section {
    margin-top: 20px;
  }

  .introduction_section .introduction_bg {
    height: 1000px;
  }

  .introduction_section .content {
    transform: translateY(-950px);
  }

  .concept_section .concept1_shadow {
    transform: translate(145px, 3px);
  }

  .concept_section .concept2_shadow {
    transform: translate(85px, 3px);
  }

  .aboutUs_section p {  
    display: block;
  }

  .aboutUs_section span {
    padding: 0px;
  }

  .aboutUs_section span hr {
    margin: auto;
  }
}

@media screen and ( max-width: 550px  ) {
  .concept_section .concept1_shadow {
    transform: translate(70px, 3px);
  }

  .concept_section .concept2_shadow {
    transform: translate(10px, 3px);
  }
}
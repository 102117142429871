.at-footer__logo_container {
  text-decoration: inherit;
  color: inherit;
  margin-bottom: 50px;

}

.at-footer__logo_container p {
  font-size: 12px;
  font-weight: 500;
  width: 200px;
  word-break: break-word;
  margin: auto;
}
.at-carousel__conatiner {
  height: 600px;
  object-fit: contain;
  transform: translateY(-600px);
}

.at-carousel__bg {
   background-size: cover;
   background-position: center;
   filter: blur(10px);
   height: 600px;
}

.carousel-item {
  height: 600px;
}

@media screen and ( max-width: 520px ) {
  .at-carousel__bg {
    height: 300px;
  }

  .at-carousel__conatiner {
    height: 300px;
    transform: translateY(-300px);
  }

  .carousel-item {
    height: 300px;
  }
}